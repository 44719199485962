import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag-next"
import { VueCookieNext } from 'vue-cookie-next'
import './css/index.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueGtag, { 
  property: { 
    id: "G-8JL17G1SZ5",
    params: {
      anonymize_ip: true,
    }
  }
})
app.use(VueCookieNext)
app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')

// set default config
VueCookieNext.config({ expire: '7d' })
 
// set global cookie
VueCookieNext.setCookie('theme', 'default')
VueCookieNext.setCookie('hover-time', { expire: '1s' })
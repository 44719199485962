<template>
  <div class="absolute map-holder w-full h-full z-50"> 
    <div v-if="overlay" @click="overlay = false" class="w-full h-full absolute z-60">
      <svg fill="#000" class="w-6 h-6 transform absolute top-16 right-28 z-50" viewBox="0 0 30.92 30.92"><path d="M8.29 0v4h15.8L0 28.09l2.83 2.83L26.92 6.83v15.8h4V0H8.29z"/></svg>
      <p class="p-locatie p-4 bg-white absolute right-8 top-20 z-40 w-48 rounded-lg text-sm shadow-2xl">Klik op 'mijn locatie' om je locatie te bekijken. Automatisch wordt je nieuwe locatie getoond.</p>
      <svg fill="#000" class="w-6 h-6 transform -rotate-90 absolute z-50 svg-marker" viewBox="0 0 30.92 30.92"><path d="M8.29 0v4h15.8L0 28.09l2.83 2.83L26.92 6.83v15.8h4V0H8.29z"/></svg>    
      <p class="p-marker p-4 bg-white absolute z-40 w-48 rounded-lg text-sm shadow-2xl">Klik op een marker om de het audiofragment af te spelen.</p>      
    </div>   
    <div class="map-corner map-corner-tl"></div>
    <div class="map-corner map-corner-tr"></div>
    <div class="map-corner map-corner-br"></div>
    <div class="map-corner map-corner-bl"></div>
    <div id="map" class="w-full h-full rounded-xl absolute" ref="map"></div>
    <a @click="buttonLive === 'mijn locatie' ? getUserLocation() : stopUserLocation()" class="rounded-full bg-black absolute top-4 right-2 text-white underline hover:no-underline text-base font-mix-bold cursor-pointer py-1 px-3">
      {{ buttonLive }}
    </a>
    <a @click="close" class="rounded-full bg-white absolute top-4 left-2 text-black underline hover:no-underline text-base font-mix-bold cursor-pointer py-1 px-3">
      audioplayer
    </a>
    <div class="absolute rounded-full pl-8 pr-3 py-1 bg-white bottom-10 left-8 text-sm font-mix-bold">
       {{ currentSong + 1 }}. {{ currentName }}
     </div>    
    <button 
      v-on:click="playAudio()" 
      title="Play/Pause Song" 
      type="button" 
      class="h-12 w-12 absolute bottom-8 left-2 rounded-full bg-black mx-auto flex flex-wrap justify-center content-center transition duration-300 ease-in-out transform hover:scale-95 focus:outline-none"
    >          
      <svg 
        v-if="!playing" 
        fill="#fff" 
        class="w-8 h-8" 
        viewBox="0 0 50 50"
      >
        <path d="M9.46 43.82V6.18L42 25zm3-32.44v27.24L36 25z" data-name="Layer 1"/>
      </svg>
      <svg
        v-else 
        fill="#ffffff" 
        class="w-8 h-8"
        viewBox="0 0 50 50"
      >
        <path d="M38 43.19H28V6.81h10zm-7.08-3H35V9.81h-4zM22 43.19H12V6.81h10zm-7.08-3H19V9.81h-4z"/>
      </svg>
    </button> 
  </div>
</template>

<script>

import {Loader} from 'google-maps'
import { mapState, mapGetters } from 'vuex'
import mapStyle from '/static/data/mapstyle.json'

// open een infowindow - fixed
// infowindow styling 
// importeer tailwind css
// audio player toevoegen aan de app

// maak er een app van


export default {
  data() {
    return {
      buttonLive: 'mijn locatie',
      overlay: true,
      locationInterval: null,
      map: null,
      google: null,
      mapCenter: { lat: 52.08193324238481, lng: 4.3229618261629295 },
      wayPointsStart: [
        { lat:52.08221313142099, lng:4.322634004390285, stopover: true }, // dh centraal
        { lat:52.079948289335945, lng:4.319206766727621, stopover: true }, 
        { lat:52.07887745942607, lng:4.318876081529513, stopover: true }, 
        { lat:52.07939022609708, lng:4.312053672072382, stopover: true } // binnenhof
      ],
      wayPointsBinnenhof: [
        { lat:52.07939022609708, lng:4.312053672072382, stopover: true }, // binnenhof
        { lat:52.08017061591256, lng:4.314810661392992, stopover: true } // binnenhof
      ],
      wayPointsEnd: [
        { lat:52.08017061591256, lng:4.314810661392992, stopover: true },
        { lat:52.081260671337986, lng:4.3129504719907965, stopover: true }, // lange vijverberg        
        { lat:52.0798114196369, lng:4.310241654020631, stopover: true }, // buitenhof
        { lat:52.07656102537928, lng:4.314459885072796, stopover: true }, 
        { lat:52.07520237273631, lng:4.309809828879912, stopover: true }, // gedempte burgwal
        { lat:52.07953108231547, lng:4.303021789811856, stopover: true }, // torenstraat
        { lat:52.076837560673376, lng:4.292284533843164, stopover: true },
        { lat:52.08045447973716, lng:4.283048776616262, stopover: true },
        { lat:52.08099349124846, lng:4.281007615628275, stopover: true },
        { lat:52.08238490183145, lng:4.277187750696701, stopover: true },
        { lat:52.087601555591476, lng:4.291774553950505, stopover: true },
        { lat:52.089272703866406, lng:4.279678036510072, stopover: true }, // museon
        { lat:52.09090752715296, lng:4.280954767938277, stopover: true }, 
        { lat:52.09173225386815, lng:4.2836157825990036, stopover: true }, 
        { lat:52.09252067827248, lng:4.287749037655895, stopover: true }, 
        { lat:52.087030414265335, lng:4.298135250206168, stopover: true }, 
        { lat:52.088608381340165, lng:4.309691918356576, stopover: true },
        { lat:52.08301801407654, lng:4.3133475011630145, stopover: true },
        { lat:52.082109849665095, lng:4.32156277656979, stopover: true },
        { lat:52.08269239372848, lng:4.3278028561431805, stopover: true },
        { lat: 52.08223475374698, lng:4.328340620395825, stopover: true },        
        { lat: 52.081965870943854, lng: 4.325192970862466, stopover: true } 

      ],
      markers: [

      ],
      directionsDisplay: [],
      directionsService: [],
      route: null,
      livePos: null,
      mapInfoWindow: null
    }
  },
  computed: {
    ...mapState(['playing', 'currentSong']),
    ...mapGetters(['currentName'])
  },
  async mounted() {
    try {
      const loader = new Loader('AIzaSyChdZjJeM6wd5T7HtG941nNkaLSuLPLB98', {});
      this.google = await loader.load();
      this.map = new this.google.maps.Map( this.$refs.map, {
        center: this.mapCenter,
        zoom: 18,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
        styles: mapStyle
      })
      // this.initMarkers()
      // this.directionsService[0] = new this.google.maps.DirectionsService()
      // this.directionsDisplay[0] = new this.google.maps.DirectionsRenderer({ preserveViewport: true, suppressMarkers: true, polylineOptions: { strokeColor: "#f8d62d" } })
      // this.directionsDisplay[0].setMap(this.map);
      
      this.calcRoute(0, this.wayPointsStart, this.google.maps.DirectionsTravelMode.BICYCLING)
      this.calcRoute(1, this.wayPointsBinnenhof, this.google.maps.DirectionsTravelMode.WALKING)
      this.calcRoute(2, this.wayPointsEnd, this.google.maps.DirectionsTravelMode.BICYCLING)
      this.initMarkers()
      this.setLine()

      this.mapInfoWindow = new this.google.maps.InfoWindow({
        content: '',
        maxWidth: 200
      })

    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    close() {
      this.$emit('closeMap')
    },
    calcRoute( number, points, mode ) {

      this.directionsService[number] = new this.google.maps.DirectionsService()
      this.directionsDisplay[number] = new this.google.maps.DirectionsRenderer({ preserveViewport: true, suppressMarkers: true, polylineOptions: { strokeColor: "#f8d62d" } })
      this.directionsDisplay[number].setMap(this.map);

      let waypts = [];
      points.forEach((point) => {
        waypts.push({
          location: new this.google.maps.LatLng(point.lat, point.lng),
          stopover: true
        })
      })

      const request = {
          origin: waypts[0].location,
          destination: waypts[waypts.length - 1].location,
          waypoints: waypts,
          optimizeWaypoints: false,
          travelMode: mode
      }

      const self = this
      this.directionsService[number].route(request, function (response, status) {
          if (status == self.google.maps.DirectionsStatus.OK) {
            // console.log(response)
            self.directionsDisplay[number].setDirections(response)
            self.route = response.routes[0]
          }
      });
    },
    initMarkers() {
      this.setMarker(new this.google.maps.LatLng(52.09811504730472, 4.292836021519339), require('../assets/images/icon-marker-rest.svg'), 1)
      this.setMarker(new this.google.maps.LatLng(52.09005828856401, 4.280686424017461), require('../assets/images/icon-marker-visit.svg'), 1) // kunstmuseum
      this.setMarker(new this.google.maps.LatLng(52.08704431692635, 4.2955389925256275), require('../assets/images/icon-marker-visit.svg'), 1) // vredespaleis
      this.setMarker(new this.google.maps.LatLng(52.086886097144465, 4.296686977974954), require('../assets/images/icon-marker-rest.svg'), 1) // vredespaleis
      this.setMarker(new this.google.maps.LatLng(52.08654328569259, 4.295775026917078), require('../assets/images/icon-marker-insta.svg'), 1) // vredespaleis
      this.setMarker(new this.google.maps.LatLng(52.080381592840126, 4.314239141229658), require('../assets/images/icon-marker-visit.svg'), 1) // mauritshuis
      this.setMarker(new this.google.maps.LatLng(52.07960996595313, 4.313488650571559), require('../assets/images/icon-marker-insta.svg'), 1) // binnenhof

      this.setInfoMarker(new this.google.maps.LatLng(52.08350240631558, 4.314323298633573), require('../assets/images/icon-marker-info.svg'), 2, 'De doorlopende afbeelding in Stalling Den Haag Centraal doet denken aan het werk van Escher, te zien in het museum waar je hier langsfietst.')
      this.setInfoMarker(new this.google.maps.LatLng(52.08015805369899, 4.3154895784849625), require('../assets/images/icon-marker-info.svg'), 2, 'Parkeer je fiets gratis in Biesieklette Plein, pak je telefoon en beluister de fragmenten over de Ridderzaal (2) op het Binnenhof en het Mauritshuis (3).') // plein
      this.setInfoMarker(new this.google.maps.LatLng(52.07732187161486, 4.315902749509958), require('../assets/images/icon-marker-info.svg'), 2, 'Zet je fiets gratis in Biesieklette Stadhuis, beluister de audio over het Stadhuis (4) en loop naar de Grote Marktstraat om meer te weten te komen over de Nieuwe Passage (5).') // plein
      this.setInfoMarker(new this.google.maps.LatLng(52.08220584828728, 4.327973781298005), require('../assets/images/icon-marker-info.svg'), 2, 'Stap van je fiets en loop het laatste stukje met de fiets aan de hand langs de Koninklijke Bibliotheek naar Den Haag Centraal, het laatste punt van de fietsroute.')

      this.setAudioMarker(new this.google.maps.LatLng(52.08193324238481, 4.3229618261629295), require('../assets/images/icon-marker-1.svg'), 100, 1)
      this.setAudioMarker(new this.google.maps.LatLng(52.07959409875627, 4.31317158808052), require('../assets/images/icon-marker-2.svg'), 200, 2)
      this.setAudioMarker(new this.google.maps.LatLng(52.08043281411358, 4.314600511124666), require('../assets/images/icon-marker-3.svg'), 300, 3)
      this.setAudioMarker(new this.google.maps.LatLng(52.077175932051695, 4.3156321120148835), require('../assets/images/icon-marker-4.svg'), 400, 4)
      this.setAudioMarker(new this.google.maps.LatLng(52.07691285937473, 4.312808514123779), require('../assets/images/icon-marker-5.svg'), 500, 5)
      this.setAudioMarker(new this.google.maps.LatLng(52.08957309279063, 4.279057518428834), require('../assets/images/icon-marker-6.svg'), 600, 6)
      this.setAudioMarker(new this.google.maps.LatLng(52.087115356085064, 4.297952491272057), require('../assets/images/icon-marker-7.svg'), 700, 7)
      this.setAudioMarker(new this.google.maps.LatLng(52.08201111637838, 4.320730016410563), require('../assets/images/icon-marker-8.svg'), 800, 8)
      this.setAudioMarker(new this.google.maps.LatLng(52.081907422436544, 4.3273942304557895), require('../assets/images/icon-marker-9.svg'), 900, 9)
      this.setAudioMarker(new this.google.maps.LatLng(52.081965870943854, 4.325192970862466), require('../assets/images/icon-marker-10.svg'), 1000, 10)
    },
    setLine() {
      const pathCoordinates = [
        { lat: 52.08193324238481, lng: 4.3229618261629295 },
        { lat: 52.08221313142099, lng: 4.322627 }
      ]
      const path = new this.google.maps.Polyline({
        path: pathCoordinates,
        geodesic: true,
        strokeColor: "#f8d62d",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      })
      path.setMap(this.map)
    },
    setMarker(points, icon, z) {
      let markerAnchor = new this.google.maps.Point(20, 20);
      let markerSize = new this.google.maps.Size(40, 40);
      let marker = new this.google.maps.Marker({
        position: points,
        map: this.map,
        icon: {
          anchor: markerAnchor,
          scaledSize: markerSize,
          url: icon,          
        },
        zIndex: z
      })
      return marker;
    },
    setInfoMarker(points, icon, z, content) {
      let marker = this.setMarker(points, icon, z)
      marker.addListener("click", () => {
        this.$gtag.event('googlemaps', {
          'event_category' : 'maps',
          'event_label' : 'click info marker'
        })
        this.mapInfoWindow.setContent(content)
        this.mapInfoWindow.open(this.map, marker)
      })
    },
    setAudioMarker(points, icon, z, content) {
      let marker = this.setMarker(points, icon, z)
      marker.addListener("click", () => {
        this.$gtag.event('googlemaps', {
          'event_category' : 'maps',
          'event_label' : 'click audio marker '+(content - 1)
        })
        this.$store.commit('setCurrentSong', content - 1)
        this.$emit('changeNumber')
        this.$emit('play')
      })
    },
    stopUserLocation() {      
      clearInterval(this.locationInterval)
      this.livePos.setMap(null)
      this.livePos.setIcon(require('../assets/images/icon-marker-trans.svg'))
      this.buttonLive = 'mijn locatie'
      this.locationInterval = null
    },
    getUserLocation() {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {        
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const point = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            if (this.livePos === null) {
              this.$gtag.event('googlemaps', {
                'event_category' : 'maps',
                'event_label' : 'live location'
              })
              this.buttonLive = 'stop mijn locatie'
              const markerAnchor = new this.google.maps.Point(10, 10);
              const markerSize = new this.google.maps.Size(20, 20);
              this.livePos = new this.google.maps.Marker({
                position: point,
                map: this.map,
                icon: {
                  anchor: markerAnchor,
                  scaledSize: markerSize,
                  url: require('../assets/images/icon-marker-live.svg')
                }
              })
              this.locationInterval = setInterval(() => { this.getUserLocation() }, 5000)
              this.map.setCenter(point)
            }
            else {
              if (this.locationInterval === null) {
                this.livePos.setIcon(require('../assets/images/icon-marker-live.svg'))
                this.locationInterval = setInterval(() => { this.getUserLocation() }, 5000)
              }
              this.buttonLive = 'stop mijn locatie'
              this.livePos.setPosition(point)
              this.map.setCenter(point)
            }
          },
          () => {
            console.log("Error: The Geolocation service failed.")
          }
        );
      } else {
        console.log("Error: Your browser doesn't support geolocation.")
      }
    },
    playAudio() {
      this.$emit('play')
    }
  }
}

</script>

<style scoped lang="scss">

.p-marker {
  left: 40%;
  top: 50%;
  margin-top: 42px;
}

.svg-marker {
  left: 40%;
  top: 50%;
  margin-top: 28px;
  margin-left: 60px;
}

.map-corner {
  width: 30px;
  height: 30px;
  position: absolute;
  overflow: hidden;
  z-index: 5;

  &:before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    box-shadow: -20px 40px 0px 50px #f8d62b;
    border-radius: 0.75rem;
  }

  &-tr {
    right: 0;
    &:before {
      right: 0;
    }
  }

  &-bl {
    bottom: 0;
    &:before {
      bottom: 0;
    }
  }

  &-br {
    right: 0;
    bottom: 0;
    &:before {
      right: 0;
      bottom: 0;
    }
  }
}

.z-60 {
  z-index: 60;
}
</style>

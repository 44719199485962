import { createStore } from 'vuex'

export default createStore({
  state: {
    currentSong: 0,
    currentName: 'Stalling Den Haag Centraal',
    playing: false,
    playlist: [
      { number: 1, title: "Stalling Den Haag Centraal", artist: "", url: require('/static/audio/1.mp3'), image: require('../assets/images/buildings/kj.jpg') },
      { number: 2, title: "Ridderzaal", artist: "", url: require('/static/audio/2.mp3'), image: require('../assets/images/buildings/ridderzaal.jpg') },
      { number: 3, title: "Mauritshuis", artist: "", url: require('/static/audio/3.mp3'), image: require('../assets/images/buildings/mauritshuis.jpg') },
      { number: 4, title: "Stadhuis", artist: "", url: require('/static/audio/4.mp3'), image: require('../assets/images/buildings/stadhuis.jpg') },
      { number: 5, title: "Nieuwe Passage", artist: "", url: require('/static/audio/5.mp3'), image: require('../assets/images/buildings/passage.jpg') },
      { number: 6, title: "Kunstmuseum", artist: "", url: require('/static/audio/6.mp3'), image: require('../assets/images/buildings/kunstmuseum.jpg') },
      { number: 7, title: "Vredespaleis", artist: "", url: require('/static/audio/7.mp3'), image: require('../assets/images/buildings/vredespaleis.jpg') },
      { number: 8, title: "Skyline", artist: "De Hoftoren, De Kroon, Zurichtoren, Binnenlandse Zaken & Koninkrijksrelaties", url: require('/static/audio/8.mp3'), image: require('../assets/images/buildings/skyline.gif') },
      { number: 9, title: "Koninklijke Bibliotheek", artist: "", url: require('/static/audio/9.mp3'), image: require('../assets/images/buildings/kb.jpg') },
      { number: 10, title: "Den Haag Centraal", artist: "", url: require('/static/audio/10.mp3'), image: require('../assets/images/buildings/denhaag-centraal.jpg') }
    ]    
  },
  mutations: {
    setCurrentSong (state, data) {
      state.currentSong = data
      state.currentName = state.playlist[data].title
    },
    setCurrentName (state, data) {
      state.currentSong = data
    },  
    setPlaying (state, data) {
      state.playing = data
    }  
  },
  getters: {
    currentName: state => {
      return state.currentName;
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div class="container relative w-full h-full overflow-scroll">
    <div class="text-center p-8 w-auto" style="width: initial;">
      <a href="#" @click="close" class="underline hover:no-underline text-white text-base font-mix-bold">sluiten</a> 
    </div>
    <ul class="px-4 pb-4">
      <playlist-item @changeSong="changeSong" v-for="(item, index) in data" :key="index" :number="index" :content="item"></playlist-item>
    </ul>
  </div>
</template>

<script>
  import PlaylistItem from '@/components/PlaylistItem'

  export default {
    components: {
      PlaylistItem
    },
    props: {
      data: Array
    }, 
    mounted () {

    },
    data () {
      return {
        activeItem: 0
      }
    },
    methods: {
      close() {
        this.$emit('closeList')
      },
      changeSong() {
        this.$emit('closeList')
        this.$emit('changeNumber')
        this.$emit('play')
      }
    }
  }
</script>
